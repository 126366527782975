import axios from 'axios'

export default function ajax (_type = '', url, data) {
  const type = _type.toLowerCase()

  return new Promise((resolve, reject) => {
    axios({
      method: type,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      url,
      data
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}
