<template>
  <div class="spinner-container flex-column">
    <i class="icon icon-spinner-solid"></i>
    <span v-if="texto" class="spinner-info">{{ texto }}</span>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: ['texto']
}
</script>

<style scoped>
  .spinner-container {
    margin: auto;
    height: 100%;
  }

  .spinner-container .icon {
    font-size: 4em;
    color: var(--main-color);

    animation: spinner 1s infinite linear;
  }

  .spinner-container .spinner-info {
    margin-top: 25px;
    color: var(--main-text);
  }

  @keyframes spinner {
    from {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
